@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #f6a700;
}
@font-face {
  font-family: "felix";
  src: local("felix"), url("assets/fonts/FelixTitling.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.felix {
  font-family: "felix", "Poppins", serif;
}
.montserrat {
  font-family: "Montserrat", sans-serif;
}
h1,
h2 {
  font-family: "felix", sans-serif;
}
button {
  font-family: "Montserrat", sans-serif;
}
@layer components {
  .flex-c {
    @apply flex justify-center items-center text-center;
  }
  .abs-c {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
  }
  .btn {
    @apply py-2.5 px-6  rounded-full font-medium transition-all duration-300 ease-in-out leading-normal;
  }
  .btn-main {
    @apply gradient-btn px-8 3xl:px-12 py-4 3xl:py-6  text-white;
  }
  .btn-second {
    @apply gradient-btn-2L dark:gradient-btn-2 px-8 3xl:px-12 py-4 3xl:py-6 text-black dark:text-white hover:opacity-80 dark:hover:brightness-125 td-300;
  }
  .min-h-dscreen {
    @apply min-h-[100dvh];
  }
  .max-w-modal {
    @apply w-screen md:max-w-2xl;
  }
  .max-screen {
    @apply px-4 lg:px-2 xl:px-0 max-w-screen-lg xl:max-w-[1150px] 2xl:max-w-[1400px] 3xl:max-w-[1800px]  mx-auto;
  }
  .td-300 {
    @apply transition-all duration-300;
  }
}
body {
  color: black;
}
body.dark {
  color: white;
}

.nav-shadow {
  box-shadow: 6px 6px 32px #00000029;
}
.card-shadow {
  box-shadow: 0px 0px 20px #00000029;
}
.input-shadow {
  box-shadow: 0px 3px 6px #00000029;
}
@media (max-width: 1024px) {
  .hide-scrollbar-mobile::-webkit-scrollbar {
    display: none;
  }
}

.gap-animation {
  animation: gap infinite 2s;
}

@keyframes gap {
  from {
    gap: 0.5rem;
  }
  to {
    gap: 2rem;
  }
}

.title-bar {
  position: relative;
}

.title-bar::before {
  content: "";
  position: absolute;
  background-image: url("/src/assets/line.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
}

.step-bar {
  position: relative;
}

.step-bar::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 2px;
  background-color: #bcbcbc;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  padding: 3rem 0;
  z-index: -1;
}

.testo-slide {
  padding-bottom: 2rem !important;
}

.testo-slide .swiper-pagination-bullet {
  background-color: #8f8f8f !important;
  width: 12px !important;
  height: 12px !important;
}

.testo-slide .swiper-pagination-bullet-active {
  background-color: var(--primary) !important;
}

.logos {
  overflow: hidden;
}

.logos-slide {
  animation: slide 12s infinite linear forwards;
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.paused {
  animation-play-state: paused;
}
.menu-blur {
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
.menu-animation {
  will-change: transform, opacity;
  contain: layout style;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.8s cubic-bezier(0.52, 0.16, 0.04, 1);
}
